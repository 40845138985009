import React from "react";
import PresaleMarquee from "../../components/PresaleMarquee";
import AntexPresaleSection from "./components/AntexPresaleSection";

const Antex = () => {
	return (
		<>
			<PresaleMarquee />
			<AntexPresaleSection />
		</>
	);
};

export default Antex;
