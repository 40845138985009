import React from "react";
import { Modal } from "react-bootstrap";
import { Close } from "../IconTheme";

const ModalLayout = ({ popup, setPopup, children }) => {
	return (
		<>
			<Modal show={popup} size="xl" centered>
				<Modal.Body>
					<div className="modal-close" onClick={() => setPopup(!popup)}>
						<Close />
					</div>
					{children}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ModalLayout;
