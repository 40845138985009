import React from "react";
import { Link } from "react-router-dom";
const TokenDeployerBanner = () => {
	return (
		<>
			<Link to="#" className="token-deployer">
				<div className="left-cont">
					<h6>Antex Token deployer</h6>
					<div className="info">Deploy using our Dapp to get </div>
				</div>
				<div className="cmn-btn">Go</div>
			</Link>
		</>
	);
};

export default TokenDeployerBanner;
