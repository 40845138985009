import React from "react";
import CopyValue from "../../Antex/components/CopyValue";
const data = ["", "", "", "", "", "", "", "", "", ""];
const StakeLeaderBoard = () => {
	return (
		<div className="stakeboard-card">
			<h6 className="title">STAKE LEADERBOARD</h6>
			<div
				className="d-flex flex-wrap justify-content-center mb-2"
				style={{ gap: "10px", marginBottom: "15px" }}
			>
				<div className="stakeboard-card-item">
					<h6 className="subtext">Total Staked</h6>
					<h5 className="subtitle">100</h5>
				</div>
				<div className="stakeboard-card-item">
					<h6 className="subtext">Total Stakers</h6>
					<h5 className="subtitle">100</h5>
				</div>
			</div>
			<table className="table __table">
				<thead>
					<tr>
						<th>Position</th>
						<th className="text-center" style={{ width: "50%" }}>
							Address
						</th>
						<th className="text-end">Stake Amount</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((item, i) => (
						<tr key={i}>
							<td>{i + 1}.</td>
							<td>
								<div className="copy-address">
									<CopyValue val="0x79283....28102" />
								</div>
							</td>
							<td className="text-end">1000 ANT</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="mt-3 text-center pb-4">
				<button className="cmn-btn" type="button">
					Load More
				</button>
			</div>
		</div>
	);
};

export default StakeLeaderBoard;
