import React from "react";
import { Link } from "react-router-dom";
const RatesCount = () => {
	return (
		<>
			<div
				className="d-flex flex-wrap rates-counts justify-content-center"
				style={{ columnGap: "17px", rowGap: "15px" }}
			>
				<Link to="#">25%</Link>
				<Link to="#">50%</Link>
				<Link to="#">75%</Link>
				<Link to="#">100%</Link>
			</div>
		</>
	);
};

export default RatesCount;
