import React from "react";
import Chart from "react-apexcharts";

const ChartCard = () => {
	const series = [44, 55, 67, 44, 55, 67];
	const options = {
		chart: {
			type: "radialBar",
		},
		colors: [
			"#429670",
			"#DE3087",
			"#F5C042",
			"#00E9AE",
			"#F81DFB",
			"#0052FF",
		],
		plotOptions: {
			radialBar: {
				dataLabels: {
					value: {
						fontSize: "12px",
					},
					name: {
						fontSize: "24px",
					},
					total: {
						show: true,
						label: "$96.4k",
						formatter: function (w) {
							return "Supply";
						},
					},
				},
			},
		},
		labels: ["Presale", "Liquidity", "Team", "Locked", "Unlocked", "Burnt"],
	};
	return (
		<>
			<div className="radial-chart">
				<Chart options={options} series={series} type="radialBar" />
				<ul className="chart-info">
					<li>
						<span className="indicator"></span>
						<span>Presale</span>
						<span>$10.0k</span>
					</li>
					<li>
						<span
							className="indicator"
							style={{ background: "#FFBD00" }}
						></span>
						<span>Liquidity</span>
						<span>$10.0k</span>
					</li>
					<li>
						<span
							className="indicator"
							style={{ background: "#F20089" }}
						></span>
						<span>Team</span>
						<span>$10.0k</span>
					</li>
					<li>
						<span
							className="indicator"
							style={{ background: "#00E9AE" }}
						></span>
						<span>Locked</span>
						<span>$10.0k</span>
					</li>
					<li>
						<span
							className="indicator"
							style={{ background: "#F81DFB" }}
						></span>
						<span>Unlocked</span>
						<span>$10.0k</span>
					</li>
					<li>
						<span
							className="indicator"
							style={{ background: "#0052FF" }}
						></span>
						<span>Burnt</span>
						<span>$10.0k</span>
					</li>
				</ul>
			</div>
		</>
	);
};

export default ChartCard;
