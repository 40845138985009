import React from "react";
import { Link } from "react-router-dom";

const AntexSocialIcons = ({ data }) => {
	return (
		<ul className="antex-social">
			{data?.map(({ icon, link }, i) => (
				<li key={i}>
					<Link to={link}>{icon}</Link>
				</li>
			))}
		</ul>
	);
};

export default AntexSocialIcons;
