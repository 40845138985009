import React from "react";

const PresaleAddress = () => {
	return (
		<div className="__presale-address">
			<ul>
				<li>
					<span>Presale Address</span>
					<span>ffas025dXFIdpd</span>
				</li>
				<li>
					<span>Sale Type</span>
					<span>Fair Launch</span>
				</li>
				<li>
					<span>Token Name</span>
					<span>SKYNET ETH</span>
				</li>
				<li>
					<span>Token Symbol</span>
					<span>SKYNET</span>
				</li>
				<li>
					<span>Tokens Sypply</span>
					<span>10,000,000,000</span>
				</li>
				<li>
					<span>Tokens For Presale</span>
					<span>5,500,000,000</span>
				</li>
				<li>
					<span>DEX Listing On</span>
					<span>UniSwap</span>
				</li>
				<li>
					<span>Tokens For Liquidity</span>
					<span>3,300,000,000</span>
				</li>
				<li>
					<span>Soft Cap</span>
					<span>1 ETH</span>
				</li>
				<li>
					<span>Presale Start Time (UTC)</span>
					<span>2023-01-07 16:30</span>
				</li>
			</ul>
		</div>
	);
};

export default PresaleAddress;
