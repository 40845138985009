import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/img/antext-icon.svg";
import AntexSocialIcons from "../../../components/AntexSocialIcons";
import {
	GlobeFill,
	LockIcon,
	TelegramFill,
	TwitterFill,
	UserBookmark,
	UserCheck,
} from "../../../IconTheme";

import bg_img from "../../../assets/img/presale-bg.png";
const socialData = [
	{
		icon: <TwitterFill />,
		link: "#",
	},
	{
		icon: <TelegramFill />,
		link: "#",
	},
	{
		icon: <GlobeFill />,
		link: "#",
	},
];
const AntexPresaleCard = () => {
	return (
		<>
			<div className="antex-card antex-card-2">
				<div
					className="d-flex flex-wrap justify-content-end __antext-top"
					style={{
						gap: "7.89px",
						background: `url(${bg_img}) no-repeat center center / cover`,
					}}
				>
					<span className={`antex-badge active`}>Audit</span>
					<span className={`antex-badge inactive`}>Kyc</span>
				</div>
				<div className="antex-top">
					<img src={img} alt="" />
					<div className="info">
						<h5 className="title">
							<Link to="#" className="text-white">
								Antex Presales
							</Link>
						</h5>
						<div className="antex-post-meta">
							<Link className="antex-post-meta-item" to="#">
								<LockIcon /> 60%
							</Link>
							<Link className="antex-post-meta-item" to="#">
								<UserCheck /> 5/200
							</Link>
							<Link className="antex-post-meta-item" to="#">
								<UserBookmark /> 5/200
							</Link>
						</div>
					</div>
					<span className="antex-badge badge-lg active">Live</span>
				</div>
				<div className="text px-2">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu
					cursus euismod quis viverra nibh. Integer feugiat scelerisque
					varius morbi
				</div>
				<ul className="antex-softcap px-2">
					<li>
						<span>Softcap:</span>
						<span>20,000 USDT</span>
					</li>
					<li>
						<span>Softcap:</span>
						<span>20,000 USDT</span>
					</li>
					<li>
						<span>Softcap:</span>
						<span>20,000 USDT</span>
					</li>
					<li>
						<span>Softcap:</span>
						<span>20,000 USDT</span>
					</li>
				</ul>
				<AntexSocialIcons data={socialData} />
			</div>
		</>
	);
};

export default AntexPresaleCard;
