import React, { useState } from "react";
import RangeSlider from "react-range-slider-input/dist/components/RangeSlider";

import "react-range-slider-input/dist/style.css";
import { Minus, Plus } from "../../../IconTheme";
const BuyRange = () => {
	const [value, setValue] = useState(10);
	const handleChange = (e) => {
		e.target.value ? setValue(parseInt(e.target.value)) : setValue(0);
	};
	return (
		<div className="range-wrapper">
			<div className="range-wrapper-inner">
				<div className="range-wrapper-top">
					<span
						className="minus"
						onClick={(e) =>
							setValue(parseInt(value >= 10 ? value - 10 : 0))
						}
					>
						<Minus />
					</span>
					<label className="value-container">
						<input
							className="input-1"
							type="number"
							value={value}
							onChange={(e) => handleChange(e)}
						/>
						<input
							type="text"
							className="w-100"
							value={`${value}.00 USDT`}
							onChange={(e) => handleChange(e)}
						/>
					</label>
					<span
						className="plus"
						onClick={(e) => setValue(parseInt(value + 10))}
					>
						<Plus />
					</span>
				</div>
				<div className="d-flex align-items-center range-input-wrapper">
					<RangeSlider
						className="single-thumb"
						defaultValue={[0, 50]}
						thumbsDisabled={[true, false]}
						rangeSlideDisabled={true}
					/>
					<span>Max</span>
				</div>
			</div>
		</div>
	);
};

export default BuyRange;
