import React from "react";
import Marquee from "react-fast-marquee";
import img from "../assets/img/antex-presale.svg";
const data = [
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
];
const PresaleMarquee = () => {
	return (
		<Marquee gradient={false} speed="40">
			{data?.map((item, i) => (
				<div className="antex-presale" key={i}>
					<img src={img} alt="" />
					<div className="info">
						<h6>ANTEX PRESALE</h6>
						<ul>
							<li>
								<span>HARDCAP:</span>
								<span>30000 USDT</span>
							</li>
							<li>
								<span>DATE:</span>
								<span>15 , JAN 2023 </span>
							</li>
							<li>
								<span>DATE:</span>
								<span>15 , JAN 2023 </span>
							</li>
						</ul>
					</div>
				</div>
			))}
		</Marquee>
	);
};

export default PresaleMarquee;
