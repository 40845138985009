import React from "react";
import { AngleDown } from "../IconTheme";

const SelectGroup = ({ label, data }) => {
	return (
		<div className="form-group">
			{label && <label className="form-label">{label}</label>}
			<div className="select-group">
				<select className="form-control">
					{data?.map(({ title, value }, i) => (
						<option value={value} key={i}>
							{title}
						</option>
					))}
				</select>
				<div className="icon">
					<AngleDown />
				</div>
			</div>
		</div>
	);
};

export default SelectGroup;
