import React from "react";
import BuyRange from "../../Antex/components/BuyRange";
import RatesCount from "../../Antex/components/RatesCount";

const Stake = ({ stake }) => {
	return (
		<>
			<div className="__stake">
				{stake === "stake" ? (
					<>
						<div className="info-list">
							<ul>
								<li>
									<span>Balance</span>
									<span>3000 ANT</span>
								</li>
								<li>
									<span>Stake Balance</span>
									<span>391 ANT</span>
								</li>
								<li>
									<span>Unlock Time</span>
									<span>16 DEC 2023</span>
								</li>
							</ul>
						</div>
						<BuyRange />
						<RatesCount />
						<Duration />
						<div className="text-center">
							<button className="cmn-btn btn--lg text-capitalize">
								Stake
							</button>
						</div>
						<div className="info-list transparent mt-0">
							<ul>
								<li>
									<span>Fee</span>
									<span>0.02 ANT</span>
								</li>
								<li>
									<span>Transaction Fee</span>
									<span>0.0000 HT</span>
								</li>
							</ul>
						</div>
					</>
				) : (
					<>
						<Duration />
						<div className="info-list">
							<ul>
								<li>
									<span>Stake Balance</span>
									<span>2000 ANT</span>
								</li>
								<li>
									<span>Unlocked</span>
									<span>True</span>
								</li>
							</ul>
						</div>
						<BuyRange />
						<RatesCount />
						<div className="text-center">
							<button className="cmn-btn btn--lg text-capitalize">
								Unstake
							</button>
						</div>
						<div className="info-list transparent mt-0">
							<ul>
								<li>
									<span>Fee</span>
									<span>0.02 ANT</span>
								</li>
								<li>
									<span>Transaction Fee</span>
									<span>0.0000 HT</span>
								</li>
							</ul>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export const Duration = () => {
	return (
		<>
			<div className="duration-wrapper">
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">7 DAYS</div>
				</div>
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">30 DAYS</div>
				</div>
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">60 DAYS</div>
				</div>
			</div>
		</>
	);
};

export default Stake;
