import React from "react";

const StakersStatistics = () => {
	return (
		<div className="py-4 py-sm-5">
			<div className="container">
				<div className="statistics-wrapper">
					<StatisticsCard title="7 - 23 %" subtitle="APR" />
					<StatisticsCard title="423" subtitle="Stakers" />
					<StatisticsCard title="482K ANT" subtitle="Total Staked" />
				</div>
			</div>
		</div>
	);
};

const StatisticsCard = ({ title, subtitle }) => (
	<div className="statistics-card">
		<div className="subtitle">{subtitle}</div>
		<div className="title">{title}</div>
	</div>
);

export default StakersStatistics;
