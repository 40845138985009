import React from "react";
import PresaleMarquee from "../../components/PresaleMarquee";
import InputGroup from "./components/InputGroup";
import TokenDeployerBanner from "./components/TokenDeployerBanner";

const CreateIDO = () => {
	return (
		<>
			<PresaleMarquee />
			<div className="pt-3 pb-5">
				<div className="container ido-container">
					<TokenDeployerBanner />
					<h2 className="create-ido-title">Create IDO</h2>
					<div className="create-ido-container">
						<div className="create-ido-item pt-4 pb-5">
							<div className="inner">
								<label className="subtitle d-block mb-2">
									Contract Address
								</label>
								<div className="input--group">
									<input
										type="text"
										className="form-control text-center"
										placeholder="TOKEN ADDRESS"
									/>
									<button className="cmn-btn">VALIDATE</button>
								</div>
								<div className="info-list">
									<ul>
										<li>
											<span>Symbol</span>
											<span>3000 ANT</span>
										</li>
										<li>
											<span>DECIMAL</span>
											<span>391 ANT</span>
										</li>
										<li>
											<span>SUPPLY</span>
											<span>16 DEC 2023</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="create-ido-item pt-4 pb-5">
							<div className="inner">
								<div className="row g-3">
									<div className="col-sm-6">
										<InputGroup
											placeholder="SALE RATE"
											label="SALE RATE"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="LISTING RATE"
											label="LISTING RATE"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="LIQUIDITY %"
											label="LIQUIDITY %"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="SOFTCAP"
											label="SOFTCAP"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="LIQUIDITY LOCK (DAYS)"
											label="LIQUIDITY LOCK (DAYS)"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="HARDCAP"
											label="HARDCAP"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="MAX BUY"
											label="MAX BUY"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="MIN BUY"
											label="MIN BUY"
											type="text"
										/>
									</div>
									<div className="col-sm-12 pt-4 text-center">
										<button className="cmn-btn btn--lg">
											VALIDATE
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="create-ido-item pt-4 pb-5">
							<div className="inner">
								<div className="row g-3">
									<div className="col-sm-6">
										<InputGroup
											placeholder="Project Name"
											label="Project Name"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="Short Description"
											label="Short Description"
											type="text"
										/>
									</div>
									<div className="col-sm-12">
										<InputGroup
											placeholder="Project Description"
											label="Project Description"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="@username"
											label="Telegram Group"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="@username"
											label="Twitter Username"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="URL"
											label="Website"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="URL"
											label="Whitepaper (if any)"
											type="text"
										/>
									</div>
									<div className="col-sm-12 pt-4 text-center">
										<button className="cmn-btn btn--lg">
											Confirm
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="create-ido-item pt-5 pb-5">
							<div className="inner">
								<div className="row g-3">
									<div className="col-sm-6">
										<InputGroup
											placeholder="Start Date"
											label="Start Date"
											type="text"
										/>
									</div>
									<div className="col-sm-6">
										<InputGroup
											placeholder="End Date"
											label="End Date"
											type="text"
										/>
									</div>
									<div className="col-12 pt-4">
										<div className="info-list">
											<ul>
												<li>
													<span>My Balance</span>
													<span>3000 ANT</span>
												</li>
												<li>
													<span>DECIMAL</span>
													<span>391 ANT</span>
												</li>
												<li>
													<span>SUPPLY</span>
													<span>16 DEC 2023</span>
												</li>
												<li>
													<span>My Balance</span>
													<span>3000 ANT</span>
												</li>
												<li>
													<span>DECIMAL</span>
													<span>391 ANT</span>
												</li>
												<li>
													<span>SUPPLY</span>
													<span>16 DEC 2023</span>
												</li>
											</ul>
											<br />
											<br />
										</div>
									</div>
									<div className="col-sm-12 pt-4 text-center">
										<button className="cmn-btn btn--lg">
											Confirm
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateIDO;
