import React from "react";

const InputGroup = ({ label, ...rest }) => {
	return (
		<div className="__form-group">
			{label && <label className="form-label">{label}</label>}{" "}
			<input className="form-control" {...rest} />{" "}
		</div>
	);
};

export default InputGroup;
