import { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Antex from "./pages/Antex/Antex";
import CreateIDO from "./pages/CreateIDO/CreateIDO";
import Home from "./pages/Home/Home";
import Staking from "./pages/Staking/Staking";

function App() {
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useLayoutEffect(() => {
			document.documentElement.scrollTo(0, 0);
		}, [location.pathname]);
		return children;
	};
	return (
		<BrowserRouter>
			<Wrapper>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/antex" element={<Antex />} />
					<Route path="/create-ido" element={<CreateIDO />} />
					<Route path="/staking" element={<Staking />} />
				</Routes>
				<Footer />
			</Wrapper>
		</BrowserRouter>
	);
}

export default App;
