import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/antext-icon.svg";
import {
	ChartIcon,
	GlobeFill,
	LockIcon,
	TelegramFill,
	TwitterFill,
	UserBookmark,
	UserCheck,
} from "../IconTheme";
import AntexSocialIcons from "./AntexSocialIcons";
import CountdownCard from "./CountdownCard";
import Progress from "./Progress";

const socialData = [
	{
		icon: <TwitterFill />,
		link: "#",
	},
	{
		icon: <TelegramFill />,
		link: "#",
	},
	{
		icon: <GlobeFill />,
		link: "#",
	},
];
const AntexCard = ({ audit, kyc }) => {
	return (
		<div className="col-lg-4 col-md-6">
			<div className="antex-card">
				<div
					className="d-flex flex-wrap justify-content-end"
					style={{ gap: "7.89px" }}
				>
					<span className={`antex-badge ${audit ? "active" : "inactive"}`}>
						Audit
					</span>
					<span className={`antex-badge ${kyc ? "active" : "inactive"}`}>
						Kyc
					</span>
				</div>
				<div className="antex-top">
					<img src={img} alt="" />
					<div className="info">
						<h5 className="title">
							<Link to="/antex" className="text-white">
								AntexPad
							</Link>
						</h5>
						<div className="d-flex flex-wrap align-items-center">
							<span className="antex-badge active me-3">Active</span>
							<ChartIcon />
						</div>
					</div>
				</div>
				<div className="antex-post-meta px-1">
					<Link className="antex-post-meta-item" to="#">
						<LockIcon /> 60%
					</Link>
					<Link className="antex-post-meta-item" to="#">
						<UserCheck /> 5/200
					</Link>
					<Link className="antex-post-meta-item" to="#">
						<UserBookmark /> 5/200
					</Link>
				</div>
				<div className="text px-1">
					Antex Provides an innovative platform for fundraising on Hepton
					L2 blockchain.
				</div>
				<ul className="antex-softcap px-1">
					<li>
						<span>Softcap:</span>
						<span>20,000 USDT</span>
					</li>
					<li>
						<span>Hardcap:</span>
						<span>40,000 USDT</span>
					</li>
					<li>
						<span>Liquidity</span>
						<span>60%</span>
					</li>
				</ul>
				<div className="antex-progress mx-1">
					<div className="antex-progress-top d-flex justify-content-between">
						<span>Round 1</span>
						<CountdownCard targetDate={`January 31, 2023 00:00:00`} />
					</div>
					<Progress title="50%" progress="50" />
					<div className="antex-progress-bottom d-flex justify-content-between">
						<span>1300 BNB</span>
						<span>1300 BNB</span>
					</div>
				</div>
				<AntexSocialIcons data={socialData} />
			</div>
		</div>
	);
};

export default AntexCard;
