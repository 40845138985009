import React from "react";
import SelectGroup from "../../../components/SelectGroup";
const data1 = [
	{
		title: "Hepton",
		value: "hepton",
	},
	{
		title: "Hepton",
		value: "hepton",
	},
	{
		title: "Hepton",
		value: "hepton",
	},
	{
		title: "Hepton",
		value: "hepton",
	},
];
const data2 = [
	{
		title: "All Status",
		value: "all status",
	},
	{
		title: "active",
		value: "active",
	},
	{
		title: "banner",
		value: "banned",
	},
	{
		title: "progress",
		value: "progress",
	},
];
const data3 = [
	{
		title: "No Sort",
		value: "no-sort",
	},
	{
		title: "A-Z",
		value: "A-Z",
	},
	{
		title: "Z-A",
		value: "Z-A",
	},
];
const FilterHeader = () => {
	return (
		<div className="d-flex flex-wrap justify-content-end filter-header">
			<SelectGroup label="Networks" data={data1} />
			<SelectGroup label="Filter By" data={data2} />
			<SelectGroup label="Filter By" data={data3} />
		</div>
	);
};

export default FilterHeader;
