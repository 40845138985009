import React, { useState } from "react";
import { AngleLeft, AngleRight } from "../IconTheme";

const Pagination = ({ postsPerPage, data, setCurrentPage }) => {
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i++) {
		pageNumbers.push(i);
	}
	const [index, setIndex] = useState(1);
	const [active, setActive] = useState(1);
	const handleNext = () => {
		if (index + 2 < postsPerPage) {
			paginate(index + 1);
			setIndex(index + 1);
			setActive(0);
		}
	};
	const handlePrev = () => {
		if (index > 1) {
			paginate(index - 1);
			setIndex(index - 1);
			setActive(0);
		}
	};
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		setActive(0);
	};
	return (
		<ul className="__pagination">
			<li className="angles" onClick={() => handlePrev()}>
				<AngleLeft />
			</li>
			{pageNumbers.map((number) => (
				<li
					key={number}
					className={`${number === index ? "active" : ""} ${
						active == number && number == 1 ? "active" : ""
					}`}
					onClick={() => {
						paginate(number);
						setIndex(number);
					}}
				>
					{number}
				</li>
			))}
			<li className="angles" onClick={() => handleNext()}>
				<AngleRight />
			</li>
		</ul>
	);
};

export default Pagination;
