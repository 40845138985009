import React from "react";

import Countdown from "react-countdown";
const renderer = ({ days, hours, minutes, seconds, completed, className }) => {
	hours = days * 24 + hours;
	if (completed) {
		return <div>00d : 00h : 00m</div>;
	} else {
		return (
			<span className={`countdown ${className}`}>
				<span className="item">
					{days > 0 && (
						<>
							<span className="subtitle">
								{days < 10 ? `0${days}` : days}d :
							</span>
						</>
					)}
					<span className="subtitle">
						{hours < 10 ? `0${hours}` : hours}h :
					</span>
					<span className="subtitle">
						{minutes < 10 ? `0${minutes}` : minutes}m
					</span>
				</span>
			</span>
		);
	}
};
const CountdownCard = ({ targetDate }) => {
	const convertDate = (date, tzString) => {
		return new Date(
			(typeof date === "string" ? new Date(date) : date).toLocaleString(
				"en-US",
				{ timeZone: tzString }
			)
		);
	};
	return (
		<Countdown
			date={convertDate(targetDate, "Asia/singapore")}
			renderer={renderer}
		/>
	);
};

export default CountdownCard;
