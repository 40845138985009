import React from "react";
import AntexSection from "./components/AntexSection";
import Banner from "./components/Banner";

const Home = () => {
	return (
		<>
			<Banner />
			<AntexSection />
		</>
	);
};

export default Home;
