import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import img1 from "../assets/img/ht-icon-1.png";
import img2 from "../assets/img/ht-icon-2.png";
import logo from "../assets/img/logo.png";
import icon3 from "../assets/img/wallet-2/coinbase.png";
import icon1 from "../assets/img/wallet-2/metamask.png";
import icon2 from "../assets/img/wallet-2/trust.png";
import icon4 from "../assets/img/wallet-2/walleto.png";
import wallet_icon from "../assets/img/wallet.png";
import wallet_icon_1 from "../assets/img/wallet/metamask.png";
import wallet_icon_2 from "../assets/img/wallet/trust.png";
import wallet_icon_3 from "../assets/img/wallet/walleto.png";
import { Bar, Close } from "../IconTheme";
import DropdownGroup from "./DropdownGroup";
import ModalLayout from "./ModalLayout";
const quick_connect = [wallet_icon_1, wallet_icon_2, wallet_icon_3];

const selectData = [
	{
		img: img1,
		name: "HT",
	},
	{
		img: img2,
		name: "MT",
	},
	{
		img: img1,
		name: "BDT",
	},
	{
		img: img2,
		name: "CBP",
	},
];

const Header = () => {
	const [menuOpen, setMenuOpen] = useState();
	const [popup, setPopup] = useState(false);
	return (
		<>
			<header>
				<div className="container">
					<div className="header-wrapper">
						<Link to="/" className="logo">
							<img src={logo} alt="" />
						</Link>
						<div
							className="bar ms-auto"
							onClick={() => setMenuOpen(!menuOpen)}
						>
							<Bar />
						</div>
						<div className={`menu-area  ${menuOpen ? "active" : ""}`}>
							<div
								className="close"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<Close />
							</div>
							<ul
								className="menu"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<li>
									<NavLink to="/">Home</NavLink>
								</li>
								<li>
									<NavLink to="/staking">Staking</NavLink>
								</li>
								<li>
									<NavLink to="/create-ido">Create IDO</NavLink>
								</li>
								<li>
									<NavLink to="/antex">Create Token</NavLink>
								</li>
								<li>
									<NavLink to="/docs">DOCS</NavLink>
								</li>
							</ul>
							<DropdownGroup data={selectData} baseColor />
							<div
								className="header-btns d-flex flex-wrap align-items-center"
								style={{ columnGap: "21px", rowGap: "12px" }}
							>
								<Link
									to="#"
									className="cmn-btn"
									onClick={() => setPopup(!popup)}
								>
									Connect Wallet
								</Link>
							</div>
						</div>
					</div>
				</div>
			</header>
			<ModalLayout setPopup={setPopup} popup={popup}>
				<div className="__modal-content">
					<div className="d-flex flex-wrap">
						<div className="__modal-content-left">
							<h4 className="title">Connect Wallet</h4>
							<div className="item">
								<h5 className="subtitle">Injected Wallets</h5>
								<ul>
									<li>
										<Link to="#">
											<img src={icon1} alt="" />
											<span>Metamask</span>
										</Link>
									</li>
									<li>
										<Link to="#">
											<img src={icon2} alt="" />
											<span>Trust Wallet</span>
										</Link>
									</li>
									<li>
										<Link to="#">
											<img src={icon3} alt="" />
											<span>Coinbase Wallet</span>
										</Link>
									</li>
								</ul>
							</div>
							<div className="item">
								<h5 className="subtitle">Wallet Connect</h5>
								<ul>
									<li>
										<Link to="#">
											<img src={icon4} alt="" />
											<span>Wallet Connect</span>
										</Link>
									</li>
								</ul>
							</div>
							<div className="item">
								<h5 className="subtitle">Hardware wallet</h5>
								<ul>
									<li>
										<Link to="#">
											<img src={icon1} alt="" />
											<span>Ledger Wallet</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="__modal-content-right">
							<h4 className="title">What is a wallet ?</h4>
							<div className="__modal-content-right-top">
								<img src={wallet_icon} alt="" />
								<div>
									Wallets are used to send, receive, store, and display
									digital assets like Ethereum and NFTs.
								</div>
							</div>
							<h4 className="title">Quick Connect</h4>
							<div className="quick-connect">
								{quick_connect?.map((item, i) => (
									<Link to="#" key={i}>
										<img src={item} alt="" />
									</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			</ModalLayout>
		</>
	);
};

export default Header;
