import React, { useState } from "react";
import AntexCard from "../../../components/AntexCard";
import Pagination from "../../../components/Pagination";
import FilterHeader from "./FilterHeader";
const antexData = [
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: false,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: false,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: false,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: false,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: false,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: false,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: false,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
	{
		audit: true,
		kyc: true,
	},
];
const AntexSection = () => {
	const [data, setData] = useState(antexData);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;

	return (
		<section className="py-5">
			<div className="container">
				<div className="mb-4">
					<FilterHeader />
				</div>
				<div className="row g-4 antext-row">
					{data
						?.map((item, i) => <AntexCard key={i} {...item} />)
						.slice(indexOfFirstPost, indexOfLastPost)}
				</div>
				<Pagination
					data={data}
					postsPerPage={postsPerPage}
					setCurrentPage={setCurrentPage}
				/>
			</div>
		</section>
	);
};
export default AntexSection;
