import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/img/antext-icon.svg";
import Progress from "../../../components/Progress";
import { UserBookmark, UserCheck } from "../../../IconTheme";
import BuyRange from "./BuyRange";
import CopyValue from "./CopyValue";
import RatesCount from "./RatesCount";

const AntexPresaleStats = () => {
	return (
		<>
			<div className="antex-card antex-card-2">
				<div className="antex-top antex-top-2 align-items-start">
					<div className="img">
						<span>Stats</span>
						<img src={img} alt="" />
					</div>
					<div className="info ps-xl-5 ps-lg-0 ps-md-4">
						<div className="copy-stats mb-3">
							Connected wallet : <CopyValue />
						</div>
						<div className="antex-post-meta">
							<Link className="antex-post-meta-item" to="#">
								<UserCheck /> 5/200
							</Link>
							<Link className="antex-post-meta-item" to="#">
								<UserBookmark /> 5/200
							</Link>
						</div>
						<h6 className="allocation-title">My Allocation</h6>
						<Progress
							title="200/400USDT"
							className="border-0 bg-131416"
							progress="50"
						/>
					</div>
				</div>
				<div className="px-sm-3">
					<BuyRange />
				</div>
				<div className="mt-4">
					<RatesCount />
				</div>
				<div className="text-center">
					<button className="cmn-btn buy-btn">Buy Now</button>
				</div>
				<ul className="antex-contribute-info px-2 pb-2">
					<li className="border-bottom border-top py-2 mb-2 px-2">
						<span>Total Contribution</span>
						<span>09</span>
					</li>
					<li className="px-2">
						<span>Sale Type</span>
						<span>0 ETH</span>
					</li>
					<li className="px-2">
						<span>Sale Type</span>
						<span>0 SKYNETH</span>
					</li>
				</ul>
			</div>
		</>
	);
};

export default AntexPresaleStats;
