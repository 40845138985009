import React from "react";
import { Link } from "react-router-dom";
import { Instagram, LinkedinIn, Twitter } from "../IconTheme";

const social_icons = [
	{
		icon: <Instagram />,
		link: "#",
	},
	{
		icon: <LinkedinIn />,
		link: "#",
	},
	{
		icon: <Twitter />,
		link: "#",
	},
];

const SocialIcons = () => {
	return (
		<>
			<ul className="social-icons">
				{social_icons &&
					social_icons.map(({ icon, link }, i) => (
						<li key={i}>
							<Link to={link}>{icon}</Link>
						</li>
					))}
			</ul>
		</>
	);
};

export default SocialIcons;
