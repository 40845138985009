import React from "react";

const WhitelistCard = () => {
	return (
		<div className="__whitelist-card">
			<input type="text" className="enter-whitelist-btn" placeholder="Enter Whitelist" />
			<div className="text-center">
				<button className="enter-whitelist-add-btn">Add</button>
			</div>
			<div className="grp-btn d-flex flex-wrap justify-content-center">
				<button className="enter-whitelist-add-btn">Add</button>
				<button className="enter-whitelist-add-btn">Add</button>
			</div>
		</div>
	);
};

export default WhitelistCard;
