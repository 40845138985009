import React from "react";

const Progress = ({ title, progress, className, ...rest }) => {
	return (
		<div className={`progress ${className}`}>
			<div className="progress-bar" style={{ width: `${progress}%` }}></div>
			<div
				className="progress-title"
				style={{
					color: `${progress < 21 ? "#fff" : "#000"}`,
					minWidth: `${progress}%`,
				}}
			>
				{title}
			</div>
		</div>
	);
};

export default Progress;
