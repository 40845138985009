import React, { useEffect, useState } from "react";
import AddressWhitelistedBadge from "./AddressWhitelistedBadge";
import AntexPresaleCard from "./AntexPresaleCard";
import AntexPresaleStats from "./AntexPresaleStats";
import ChartCard from "./ChartCard";
import PresaleAddress from "./PresaleAddress";
import WhitelistCard from "./WhitelistCard";

const AntexPresaleSection = () => {
	const [mobileScreen, setMobileScreen] = useState(true);

	const handleMobile = () => {
		const width = window.innerWidth;
		if (width < 992) {
			setMobileScreen(true);
		} else {
			setMobileScreen(false);
		}
	};

	useEffect(() => {
		handleMobile();
		window.addEventListener("resize", handleMobile);
	}, []);
	return (
		<section className="antext-section">
			<div className="container __container">
				<div className="row g-4 gy-xl-5">
					<div className="col-lg-6 pe-xl-3 d-flex flex-column left-items">
						<AntexPresaleCard />
						{mobileScreen && (
							<>
								<AddressWhitelistedBadge />
								<div
									className="d-flex flex-column"
									style={{ gap: "40px" }}
								>
									<AntexPresaleStats />
								</div>
							</>
						)}
						<PresaleAddress />
						<WhitelistCard />
					</div>
					<div className="col-lg-6 ps-xl-3">
						<>
							{!mobileScreen && <AddressWhitelistedBadge />}
							<div
								className="d-flex flex-column"
								style={{ gap: "40px" }}
							>
								{!mobileScreen && <AntexPresaleStats />}
								<ChartCard />
							</div>
						</>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AntexPresaleSection;
